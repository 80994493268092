import { RouteConfig } from 'vue-router';

const DetailsView = () => import('@/views/details.vue');
const TaxDetailsViewIndex = () =>
  import('@/views/details/reestr/tax/index.vue');
const TaxDetailsViewId = () => import('@/views/details/reestr/tax/_id.vue');

export default {
  path: '/details',
  component: DetailsView,
  children: [
    {
      path: 'tax',
      name: 'tax-details-index',
      redirect: { name: 'tax-details-id' },
      component: TaxDetailsViewIndex,
      children: [
        {
          path: ':id',
          name: 'tax-details-id',
          component: TaxDetailsViewId,
        },
      ],
    },
  ],
} as RouteConfig;

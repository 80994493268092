import { ApiService } from '@/api/services/core/ApiService';
import { axiosInstance } from '@/api/axios';
import ENDPOINTS from '@/api/endpoints';
import { Akimat } from '@/api/services/AkimatsService/types';

export default class AkimatsService extends ApiService {
  public getList() {
    return axiosInstance.get<Akimat>(ENDPOINTS.akimats.index);
  }

  public getByCode(code: string) {
    return axiosInstance.get<Akimat>(ENDPOINTS.akimats.byCode(code));
  }

  public editAkimat(payload: Partial<Akimat>) {
    return axiosInstance.post<Akimat>(ENDPOINTS.akimats.index, payload);
  }
}

import { RouteConfig } from 'vue-router';

const UploadsView = () => import('@/views/uploads.vue');
const UploadsViewIndex = () => import('@/views/uploads/index.vue');

const UploadModuleView = () => import('@/views/uploads/module.vue');
const UploadModuleViewIndex = () => import('@/views/uploads/module/index.vue');

const UploadHistoryView = () => import('@/views/uploads/history.vue');
const UploadHistoryViewIndex = () =>
  import('@/views/uploads/history/index.vue');

export default {
  path: '/uploads',
  component: UploadsView,
  children: [
    {
      path: '',
      name: 'uploads-index',
      redirect: 'module',
      component: UploadsViewIndex,
    },
    {
      path: 'module',
      component: UploadModuleView,
      children: [
        {
          path: '',
          name: 'uploads-module-index',
          component: UploadModuleViewIndex,
        },
      ],
    },
    {
      path: 'history',
      name: 'history-index',
      component: UploadHistoryView,
      redirect: { name: 'uploads-history-index' },
      children: [
        {
          path: '',
          name: 'uploads-history-index',
          component: UploadHistoryViewIndex,
        },
      ],
    },
  ],
} as RouteConfig;

import Vue from 'vue';
import Vuex from 'vuex';
import { RootState } from '@/store/types';
import { user } from '@/store/user';
import { admin } from '@/store/admin';
import { budgetInfo } from '@/store/budget-info';
import { akimats } from '@/store/akimats';
import { loader } from '@/store/loader';

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    admin,
    budgetInfo,
    akimats,
    loader,
  },
});

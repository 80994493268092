import { ActionTree } from 'vuex';
import { UserState } from '@/store/user/types';
import { RootState } from '@/store/types';
import { Credentials } from '@/api/services/AuthService/types';
import services from '@/api/services';

export const actions: ActionTree<UserState, RootState> = {
  async LOGIN({ commit }, props: Credentials) {
    try {
      const response = await services.auth.login(props);
      commit('setError');
      commit('setKey', response.data);
    } catch (e) {
      commit('setError', e);
      console.error(e);
    }
  },
  async LOG_OUT({ commit }) {
    commit('removeKey');
    commit('setInfo', null);
  },
  async FETCH_INFO({ commit }) {
    try {
      const response = await services.user.getUserInfo();
      commit('setInfo', response.data);
    } catch (e) {
      console.error(e);
    }
  },
};

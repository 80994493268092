import { RouteConfig } from 'vue-router';

const InfoView = () => import('@/views/info.vue');
const InfoViewIndex = () => import('@/views/info/index.vue');

const InfoStatsView = () => import('@/views/info/stats.vue');
const InfoStatsViewIndex = () => import('@/views/info/stats/index.vue');
const InfoStatsPlanView = () => import('@/views/info/stats/plan.vue');
const InfoStatsFactView = () => import('@/views/info/stats/fact.vue');

const InfoMapView = () => import('@/views/info/map.vue');
const InfoMapViewIndex = () => import('@/views/info/map/index.vue');

const InfoStructureView = () => import('@/views/info/structure.vue');
const InfoStructureViewIndex = () => import('@/views/info/structure/index.vue');

export default {
  path: '/info',
  redirect: { name: 'info-tax-index' },
  component: InfoView,
  children: [
    {
      path: '',
      name: 'info-index',
      redirect: { name: 'info-stats' },
      component: InfoViewIndex,
    },
    {
      path: 'stats',
      name: 'info-stats',
      redirect: { name: 'info-stats-index' },
      component: InfoStatsView,
      children: [
        {
          path: '',
          name: 'info-stats-index',
          component: InfoStatsViewIndex,
        },
        {
          path: 'plan',
          name: 'info-stats-plan',
          component: InfoStatsPlanView,
        },
        {
          path: 'fact',
          name: 'info-stats-fact',
          component: InfoStatsFactView,
        },
      ],
    },
    {
      path: 'map',
      name: 'info-map',
      redirect: { name: 'info-map-index' },
      component: InfoMapView,
      children: [
        {
          path: '',
          name: 'info-map-index',
          component: InfoMapViewIndex,
        },
      ],
    },
    {
      path: 'structure',
      name: 'info-structure',
      redirect: { name: 'info-structure-index' },
      component: InfoStructureView,
      children: [
        {
          path: '',
          name: 'info-structure-index',
          component: InfoStructureViewIndex,
        },
      ],
    },
  ],
} as RouteConfig;

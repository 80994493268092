import Vue from 'vue';
import VueI18n from 'vue-i18n';

import ElementLocale from 'element-ui/lib/locale';

import ruLocaleEl from 'element-ui/lib/locale/lang/ru-RU';
import kzLocaleEl from 'element-ui/lib/locale/lang/kz';
import { axiosAuthInstance } from '@/api/axios';
import ENDPOINTS from '@/api/endpoints';
import { delay } from '@/utils/async';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
});

function setI18nLanguage(lang: string) {
  i18n.locale = lang;
  localStorage.setItem('lang', lang);
  ElementLocale.i18n((key: any, value: any) => i18n.t(key, value));
  return lang;
}

export const loadedLanguages: string[] = [];

export async function loadLanguageAsync(lang: string) {
  if (
    (i18n.locale === lang && loadedLanguages.includes(lang)) ||
    loadedLanguages.includes(lang)
  ) {
    return setI18nLanguage(lang);
  }

  await delay(500); // todo: temp
  const response = await axiosAuthInstance.get(ENDPOINTS.locale.index, {
    params: { lang },
  });

  i18n.setLocaleMessage(lang, {
    ...response.data,
    ...(lang === 'ru' ? ruLocaleEl : kzLocaleEl),
  });
  loadedLanguages.push(lang);

  return setI18nLanguage(lang);
}

export default i18n;

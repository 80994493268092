import { GetterTree } from 'vuex';
import { UserState } from '@/store/user/types';
import { RootState } from '@/store/types';

export const getters: GetterTree<UserState, RootState> = {
  token: (state) => state.key?.access_token,
  refreshToken: (state) => state.key?.refresh_token,

  isLoggedIn: (state) => !!state.key,
  hasError: (state) => !!state.error,
  info: (state) => state.info,
  name: (state) => {
    if (!state.info) return null;
    const [lastName, firstName, patronymic] = state.info.fio.split(' ');
    return {
      lastName,
      firstName,
      patronymic,
    };
  },
};

import { MutationTree } from 'vuex';
import { IRoles, IUser } from '@/api/services/AdminService/types';
import { Pageable } from '@/types/pagination';
import { AdminState, RoleTable } from '@/store/admin/types';

export const mutations: MutationTree<AdminState> = {
  setUsers(state, payload: Pageable<IUser>) {
    state.users = payload;
  },
  setRoles(state, payload: IRoles[]) {
    state.roles = payload;
  },
  setRoleTables(state, payload: RoleTable[]) {
    state.roleTables = payload;
  },
  setFetchStatus(state, status) {
    state.isFetch = status;
  },
};

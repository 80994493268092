import { MutationTree } from 'vuex';
import { UserState } from '@/store/user/types';
import { AuthData } from '@/api/services/AuthService/types';
import { UserInfo } from '@/api/services/UserService/types';
import { AxiosError } from 'axios';

export const mutations: MutationTree<UserState> = {
  setKey(state, payload: AuthData) {
    localStorage.setItem('auth', JSON.stringify(payload));
    state.key = payload;
  },
  removeKey(state) {
    localStorage.removeItem('auth');
    state.key = null;
  },
  setInfo(state, payload: UserInfo) {
    state.info = payload;
  },
  setError(state, payload: AxiosError | null = null) {
    state.error = payload;
  },
};

import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import services from '@/api/services';
import { PageableQueryParams } from '@/types/pagination';
import { AdminState, RoleTable } from '@/store/admin/types';
import { IRoles } from '@/api/services/AdminService/types';

export const actions: ActionTree<AdminState, RootState> = {
  async FETCH_USERS({ commit }, params?: PageableQueryParams) {
    try {
      commit('setFetchStatus', true);
      const response = await services.admin.users(params || {});
      commit('setUsers', response.data);
    } catch (e) {
      console.error(e);
    } finally {
      commit('setFetchStatus', false);
    }
  },
  async FETCH_ROLES({ commit, dispatch }) {
    try {
      commit('setFetchStatus', true);
      const response = await services.admin.roles();
      dispatch('FORMAT_ROLES_TREE', response.data);
      commit('setRoles', response.data);
    } catch (e) {
      console.error(e);
    } finally {
      commit('setFetchStatus', false);
    }
  },
  FORMAT_ROLES_TREE({ commit }, raw: IRoles[]) {
    const roleHeaders = raw.map((role) => ({
      prop: role.name,
      label: role.value,
    }));
    const firstTable: RoleTable = {
      headers: [
        {
          label: '',
          prop: 'head',
        },
        ...roleHeaders,
      ],
      rows: [],
    };

    const tree = new Map<string, Map<string, object>>();
    raw.forEach((role) => {
      const subjects = role.actions;
      subjects.forEach((el) => {
        let group = tree.get(el.group);
        if (!group) {
          tree.set(el.group, new Map());
          group = tree.get(el.group);
        }
        if (group) {
          const action = group.get(el.action);
          if (!action) {
            group.set(el.action, { [role.name]: true });
          } else {
            Object.assign(action, { [role.name]: true });
          }
        }
      });
    });

    const treeArray = Array.from(tree, ([key, value]) => ({
      key,
      value: Array.from(value, ([key, value]) => ({ key, value })),
    }));

    const tables: RoleTable[] = treeArray.map((group) => {
      const rows: any[] = [];
      group.value.forEach((action) => {
        rows.push({
          head: action.key,
          ...action.value,
        });
      });
      return {
        headers: [{ label: group.key, prop: 'head' }, ...roleHeaders],
        rows,
      };
    });

    commit('setRoleTables', [firstTable, ...tables]);
  },
};

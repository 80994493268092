import { Module } from 'vuex';
import { AkimatsState } from '@/store/akimats/types';
import { RootState } from '@/store/types';
import { mutations } from '@/store/akimats/mutations';
import { actions } from '@/store/akimats/actions';
import { getters } from '@/store/akimats/getters';

export const akimats: Module<AkimatsState, RootState> = {
  namespaced: true,
  state: () => ({
    list: undefined,
    tree: undefined,
  }),
  actions,
  mutations,
  getters,
};

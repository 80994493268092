import { GetterTree } from 'vuex';
import { BudgeTaxInfo, BudgetInfoState } from '@/store/budget-info/types';
import { RootState } from '@/store/types';
import { ReportComparison } from '@/api/services/ReportsService/types';

export const getters: GetterTree<BudgetInfoState, RootState> = {
  selectedAkimatId: (state) => state.selectedAkimatId,
  data: (state) => state.data,
  rating: (state) => state.rating,
  detailedData: (state) => state.detailedData,
  taxInfo: (state): BudgeTaxInfo => {
    const { totalFactTaxes, totalPlanTaxes, difference, factTaxesCount } =
      state.data as ReportComparison;

    const fraction = (totalFactTaxes * 100) / totalPlanTaxes;

    return {
      plan: totalPlanTaxes,
      hasPlan: totalPlanTaxes > 0,
      fact: totalFactTaxes,
      hasFact: totalFactTaxes > 0,
      factPercentage: fraction,
      difference: Math.abs(difference),
      differencePercentage: 100 - fraction,
      differencePercentageAbsolute: Math.abs(100 - fraction),
      isPlanCompleted: 100 - fraction <= 0,
      taxpayersCount: factTaxesCount,
    };
  },
  history: (state) => state.history,
  isFetch: (state) => state.isFetch,
};

import { ApiService } from '@/api/services/core/ApiService';
import ENDPOINTS from '@/api/endpoints';
import { AuthData, Credentials } from '@/api/services/AuthService/types';
import { axiosAuthInstance } from '@/api/axios';

export class AuthService extends ApiService {
  public login(props: Credentials) {
    return axiosAuthInstance.post<AuthData>(ENDPOINTS.auth.index, props);
  }

  public refresh(props: { refreshToken: string }) {
    return axiosAuthInstance.post<AuthData>(ENDPOINTS.auth.refresh, props);
  }
}

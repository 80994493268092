import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { AdminState } from '@/store/admin/types';

export const getters: GetterTree<AdminState, RootState> = {
  users: (state) => state.users,
  roles: (state) => state.roles,
  roleTables: (state) => state.roleTables,
  rolesAsOptions: (state) =>
    state.roles.map(
      ({ name: value, value: label }: { name: string; value: string }) => ({
        value,
        label,
      }),
    ),
  isFetch: (state) => state.isFetch,
};

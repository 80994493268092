import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/routes/index';
import store from '@/store';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!store.getters['user/isLoggedIn'] && to?.meta?.public !== true)
    next({ path: '/auth' });
  else next();
});

export default router;

import { ApiService } from '@/api/services/core/ApiService';
import ENDPOINTS from '@/api/endpoints';
import { Pageable, PageableQueryParams } from '@/types/pagination';
import { IRoles, IUser } from '@/api/services/AdminService/types';
import { axiosInstance } from '@/api/axios';

export class AdminService extends ApiService {
  public users(params: PageableQueryParams) {
    return axiosInstance.get<Pageable<IUser>>(ENDPOINTS.admin.users.index, {
      params,
    });
  }

  public addUser(payload: IUser) {
    return axiosInstance.post<IUser>(ENDPOINTS.admin.users.index, payload);
  }

  public editUser(payload: IUser & { id: number }) {
    return axiosInstance.put<IUser>(
      ENDPOINTS.admin.users.edit(payload.id),
      payload,
    );
  }

  public deleteUser(id: number) {
    return axiosInstance.delete<number>(ENDPOINTS.admin.users.delete(id));
  }

  public roles() {
    return axiosInstance.get<IRoles[]>(ENDPOINTS.admin.users.roles);
  }
}

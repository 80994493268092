import { RouteConfig } from 'vue-router';

const AuthView = () => import('@/views/auth.vue');
const AuthViewIndex = () => import('@/views/auth/index.vue');

export default {
  path: '/auth',
  component: AuthView,
  meta: { public: true },
  children: [
    {
      path: '',
      name: 'auth-index',
      meta: { layout: 'auth', public: true },
      component: AuthViewIndex,
    },
  ],
} as RouteConfig;

import { RouteConfig } from 'vue-router';

const TaxesView = () => import('@/views/taxes.vue');
const TaxesViewIndex = () => import('@/views/taxes/index.vue');

export default {
  path: '/taxes',
  component: TaxesView,
  children: [
    {
      path: '',
      name: 'taxes-index',
      component: TaxesViewIndex,
    },
  ],
} as RouteConfig;

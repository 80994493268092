import { RouteConfig } from 'vue-router';

import AuthRoutes from '@/router/routes/auth';
import InfoRoutes from '@/router/routes/info';
import ReestrRoutes from '@/router/routes/reestr';
import TaxesRoutes from '@/router/routes/taxes';
import ControlRoutes from '@/router/routes/control';
import UploadsRoutes from '@/router/routes/uploads';
import Details from '@/router/routes/details';
import RoleModel from '@/router/routes/roleModel';

const routes: Array<RouteConfig> = [
  AuthRoutes,
  InfoRoutes,
  ReestrRoutes,
  TaxesRoutes,
  ControlRoutes,
  UploadsRoutes,
  Details,
  RoleModel,
  {
    path: '*',
    redirect: '/auth',
  },
];

export default routes;

import { RouteConfig } from 'vue-router';

const ReestrView = () => import('@/views/reestr.vue');
const ReestrViewIndex = () => import('@/views/reestr/index.vue');

const TaxView = () => import('@/views/reestr/tax.vue');
const TaxViewIndex = () => import('@/views/reestr/tax/index.vue');

export default {
  path: '/reestr',
  component: ReestrView,
  children: [
    {
      path: '',
      name: 'reestr-index',
      redirect: { name: 'reestr-tax-index' },
      component: ReestrViewIndex,
    },
    {
      path: 'tax',
      component: TaxView,
      children: [
        {
          path: '',
          name: 'reestr-tax-index',
          component: TaxViewIndex,
        },
      ],
    },
  ],
} as RouteConfig;

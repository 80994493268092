import { RouteConfig } from 'vue-router';

const ControlView = () => import('@/views/control.vue');
const ControlViewIndex = () => import('@/views/control/index.vue');

const StructureView = () => import('@/views/control/structure.vue');
const StructureViewIndex = () => import('@/views/control/structure/index.vue');

const RolesUsersView = () => import('@/views/control/roles-users.vue');
const RolesUsersViewIndex = () =>
  import('@/views/control/roles-users/index.vue');

export default {
  path: '/control',
  component: ControlView,
  children: [
    {
      path: '',
      name: 'control-index',
      redirect: 'structure',
      component: ControlViewIndex,
    },
    {
      path: 'structure',
      component: StructureView,
      children: [
        {
          path: '',
          name: 'control-structure-index',
          component: StructureViewIndex,
        },
      ],
    },
    {
      path: 'roles-users',
      component: RolesUsersView,
      children: [
        {
          path: '',
          name: 'control-roles-users-index',
          component: RolesUsersViewIndex,
        },
      ],
    },
  ],
} as RouteConfig;

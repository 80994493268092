import { ApiService } from '@/api/services/core/ApiService';
import { axiosInstance } from '@/api/axios';
import ENDPOINTS from '@/api/endpoints';
import {
  ReportApprovement,
  ReportComparison,
  ReportComparisonProps,
  ReportComparisonRating,
  ReportComparisonRatingProps,
  ReportDetailedComparsion,
  SaveableReportProps,
} from '@/api/services/ReportsService/types';
import { Pageable, PageableQueryParams } from '@/types/pagination';

export default class ReportsService extends ApiService {
  public getComparison(props: ReportComparisonProps) {
    return axiosInstance.get<ReportComparison>(
      ENDPOINTS.reports.comparison.index,
      {
        params: props,
      },
    );
  }

  public getComparisonRating(props: ReportComparisonRatingProps) {
    return axiosInstance.get<ReportComparisonRating[]>(
      ENDPOINTS.reports.comparison.list,
      {
        params: props,
      },
    );
  }

  public save(props: SaveableReportProps) {
    const { file, ...params } = props;
    const formData = new FormData();
    formData.append('file', file, file.name);
    return axiosInstance.post(ENDPOINTS.reports.save, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
      transformRequest: (formData) => formData,
      params,
    });
  }

  public cancelSave(props: ReportApprovement) {
    return axiosInstance.post(ENDPOINTS.reports.cancel, props);
  }

  public approveSave(props: ReportApprovement) {
    return axiosInstance.post(ENDPOINTS.reports.approve, props);
  }

  public getHistory(params?: PageableQueryParams) {
    return axiosInstance.get(ENDPOINTS.reports.history, { params });
  }

  public getDetailedComparison(
    params: ReportComparisonProps & PageableQueryParams,
  ) {
    return axiosInstance.get<Pageable<ReportDetailedComparsion>>(
      ENDPOINTS.reports.comparison.detailed,
      {
        params,
      },
    );
  }
}

export type EndpointBranch =
  | string
  | ((props: any) => string)
  | { [key: string]: EndpointBranch };

export function wrap<T extends { [key: string]: EndpointBranch }>(
  path: string,
  arg: T,
): T {
  type ValueType = T[Extract<keyof T, string>];
  for (const field in arg) {
    const value = arg[field];
    if (typeof value === 'string') {
      arg[field] = (path + value) as ValueType;
    } else if (typeof value === 'function') {
      arg[field] = ((props) => path + value(props)) as ValueType;
    } else {
      arg[field] = wrap(
        path + '/' + field,
        arg[field] as { [key: string]: EndpointBranch },
      ) as ValueType;
    }
  }
  return arg;
}

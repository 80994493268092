import { wrap } from '@/utils/endpoints';

const ENDPOINTS = wrap('', {
  auth: {
    index: '',
    refresh: '/refresh',
  },
  admin: {
    users: {
      index: '',
      delete: (id: number) => `/${id}`,
      edit: (id: number) => `/${id}`,
      roles: '/roles',
    },
  },
  users: {
    index: '',
  },
  akimats: {
    index: '',
    byCode: (code: string) => `/${code}`,
  },
  reports: {
    history: '/history',
    save: '/save',
    cancel: '/cancel',
    approve: '/approve',
    lastReport: '/lastReport',
    comparison: {
      index: '',
      detailed: '/detailed',
      list: '/list',
    },
  },
  locale: {
    index: '',
  },
  test: {
    index: '',
  },
});

export default ENDPOINTS;

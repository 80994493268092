import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import i18n from '@/i18n';
import ElementUI from 'element-ui';

import '@/assets/vendor/element-ui.css';
import 'normalize.css';
import '@/assets/styles/index.scss';

import '@/filters/index';

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(ElementUI);
Vue.component('default-layout', () => import('@/layouts/default.vue'));
Vue.component('auth-layout', () => import('@/layouts/auth.vue'));

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

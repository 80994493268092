import { RouteConfig } from 'vue-router';

const RolesModelView = () => import('@/views/roles-model.vue');
const RolesModelViewIndex = () => import('@/views/roles-model/index.vue');

export default {
  path: '/roles-model',
  component: RolesModelView,
  children: [
    {
      path: '',
      name: 'roles-model-index',
      component: RolesModelViewIndex,
    },
  ],
} as RouteConfig;

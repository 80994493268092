import { Module } from 'vuex';
import { UserState } from '@/store/user/types';
import { RootState } from '@/store/types';

import { actions } from '@/store/user/actions';
import { getters } from '@/store/user/getters';
import { mutations } from '@/store/user/mutations';
import { AuthData } from '@/api/services/AuthService/types';

export const user: Module<UserState, RootState> = {
  namespaced: true,
  state: () => {
    let key: AuthData | null = null;
    const lsKey = localStorage.getItem('auth');
    if (lsKey) key = JSON.parse(lsKey);
    return {
      key,
      info: null,
      error: null,
    };
  },
  actions,
  getters,
  mutations,
};

import axios, {
  AxiosError,
  AxiosRequestConfig,
  CreateAxiosDefaults,
} from 'axios';
import store from '@/store';
import services from '@/api/services';
import { AuthData } from '@/api/services/AuthService/types';
import router from '@/router';

type ExtendedAxiosRequestConfig =
  | (AxiosRequestConfig & {
      isRetry: boolean;
    })
  | undefined;

const isLocal = window.location.hostname.includes('localhost');

const axiosConfig: CreateAxiosDefaults = {
  baseURL: process.env.VUE_APP_API_URL || '/',
  headers: {
    'Content-Type': 'application/json',
  },
  params: {
    ...(isLocal && { local: isLocal }),
  },
};

export const axiosInstance = axios.create(axiosConfig);
export const axiosAuthInstance = axios.create(axiosConfig);

export const getBearer = (token: string) => `Bearer ${token || ''}`;

const refresh = async () => {
  const refreshToken: string = store.getters['user/refreshToken'] || '';
  const response = await services.auth.refresh({ refreshToken });
  return response.data;
};

let isRefreshing: Promise<AuthData> | null = null;

axiosInstance.interceptors.request.use(
  async (config) => {
    await isRefreshing;
    if (config.headers) {
      config.headers['Authorization'] = getBearer(store.getters['user/token']);
    }
    return config;
  },
  (error) => error,
);

axiosInstance.interceptors.response.use(
  (res) => res,
  async (error: AxiosError) => {
    const config = error.config as ExtendedAxiosRequestConfig;
    if (error?.response?.status === 401 && config && !config.isRetry) {
      try {
        isRefreshing = isRefreshing ? isRefreshing : refresh();
        const data = await isRefreshing;
        store.commit('user/setKey', data);
        config.isRetry = true;
        isRefreshing = null;
        return axiosInstance.request(config);
      } catch (error) {
        console.error(error);
        store.commit('user/removeKey');
        await router.push('/auth');
      }
    }
    return Promise.reject(error);
  },
);

export default { axiosInstance };

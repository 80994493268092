import { Module } from 'vuex';
import { BudgetInfoState } from '@/store/budget-info/types';
import { RootState } from '@/store/types';
import { actions } from '@/store/budget-info/actions';
import { mutations } from '@/store/budget-info/mutations';
import { getters } from '@/store/budget-info/getters';

export const budgetInfo: Module<BudgetInfoState, RootState> = {
  namespaced: true,
  state: () => ({
    selectedAkimatId: null,
    data: {
      akimatId: null,
      difference: 0,
      factTaxesCount: 0,
      reportYear: null,
      totalFactTaxes: 0,
      totalPlanTaxes: 0,
    },
    rating: [],
    detailedData: [],
    history: [],
    isFetch: false,
  }),
  actions,
  mutations,
  getters,
};

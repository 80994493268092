import { ApiService } from '@/api/services/core/ApiService';
import { axiosInstance } from '@/api/axios';
import ENDPOINTS from '@/api/endpoints';
import { UserInfo } from '@/api/services/UserService/types';

export default class UserService extends ApiService {
  public getUserInfo() {
    return axiosInstance.get<UserInfo>(ENDPOINTS.users.index);
  }
}

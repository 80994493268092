import { LoaderState } from '@/store/loader/types';
import { Module } from 'vuex';
import { RootState } from '@/store/types';

import { mutations } from '@/store/loader/mutations';
import { getters } from '@/store/loader/getters';

export const loader: Module<LoaderState, RootState> = {
  namespaced: true,
  state: () => ({
    active: true,
  }),
  mutations,
  getters,
};

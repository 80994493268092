import { Module } from 'vuex';
import { AdminState } from '@/store/admin/types';
import { RootState } from '@/store/types';

import { actions } from '@/store/admin/actions';
import { getters } from '@/store/admin/getters';
import { mutations } from '@/store/admin/mutations';

export const admin: Module<AdminState, RootState> = {
  namespaced: true,
  state: () => {
    return {
      users: [],
      roles: [],
      roleTables: [],
      isFetch: false,
    };
  },
  actions,
  getters,
  mutations,
};

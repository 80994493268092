interface ChildrenHolder<T> {
  children?: T[];
}

type TreeItem<T> = T & ChildrenHolder<T>;

export const tree = <T>(source: TreeItem<T>[]) => {
  const value: TreeItem<T>[] = [...source];

  return {
    get(): TreeItem<T>[] {
      return value;
    },
    filter(cb: (node: T) => boolean) {
      const result: T[] = [];
      value.forEach((el) => {
        if (cb(el)) result.push(el);
        if (el.children) {
          result.push(...tree(el.children).filter(cb).get());
        }
      });
      return tree(result);
    },
    select(cb: (node: T) => boolean) {
      const result: T[] = [];
      value.forEach((el) => {
        if (cb(el)) {
          result.push({ ...el, children: undefined });
        } else if (el.children) {
          const children = tree(el.children).select(cb).get();
          if (children.length) result.push({ ...el, children: [children[0]] });
        }
      });
      return tree(result);
    },
    children() {
      const result: T[] = [];
      value.forEach((el) => {
        if (el.children) result.push(...el.children);
      });
      return tree(result);
    },
    map<U>(cb: (node: T) => U): U[] {
      const result: U[] = [];
      value.forEach((el) => {
        result.push({
          ...cb(el),
          ...(el.children && { children: tree(el.children).map(cb) }),
        });
      });
      return result;
    },
    flatMap<U>(cb: (node: T) => U): U[] {
      const result: U[] = [];
      value.forEach((el) => {
        result.push(cb(el));
        if (el.children) {
          result.push(...tree(el.children).flatMap(cb));
        }
      });
      return result;
    },
  };
};

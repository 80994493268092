import { AuthService } from '@/api/services/AuthService';
import { AdminService } from '@/api/services/AdminService';
import UserService from '@/api/services/UserService';
import ReportsService from '@/api/services/ReportsService';
import AkimatsService from '@/api/services/AkimatsService';

const services = {
  auth: new AuthService(),
  admin: new AdminService(),
  user: new UserService(),
  reports: new ReportsService(),
  akimats: new AkimatsService(),
};

export default services;

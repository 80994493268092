import { MutationTree } from 'vuex';
import { BudgetInfoState } from '@/store/budget-info/types';
import {
  ReportComparison,
  ReportComparisonRating,
  ReportDetailedComparsion,
  ReportHistory,
} from '@/api/services/ReportsService/types';

export const mutations: MutationTree<BudgetInfoState> = {
  setSelectedAkimatId(state, payload: string) {
    state.selectedAkimatId = payload;
  },
  setRating(state, payload: ReportComparisonRating[]) {
    state.rating = payload;
  },
  setData(state, payload: ReportComparison) {
    state.data = payload;
  },
  setHistory(state, payload: ReportHistory[]) {
    state.history = payload;
  },
  setDetailedData(state, payload: ReportDetailedComparsion) {
    state.detailedData = payload;
  },
  setFetchStatus(state, status) {
    state.isFetch = status;
  },
};

import { ReportType } from '@/constants/file-types';
import { PageableQueryParams } from '@/types/pagination';

export enum TaxpayerType {
  FL = 'FL',
  IP = 'IP',
  KFH = 'KFH',
  UL = 'UL',
}

export enum PaymentsType {
  PLAN = 'plan',
  FACT = 'fact',
}

export enum Subject {
  TAX_PAYMENTS = 'TAX_PAYMENTS',
}

export type SaveableReportProps = {
  akimatId: string;
  file: File;
  fullUserName: string;
  reportYear: number;
  subject: Subject;
  type: ReportType;
  userId: string;
};

export type ReportApprovement = {
  akimatId: string;
  reportType: ReportType;
  reportYear: number;
  subject: Subject;
  userId: string;
};

export interface ReportComparison {
  akimatId: string | null;
  difference: number;
  factTaxesCount: number;
  reportYear: number | null;
  totalFactTaxes: number;
  totalPlanTaxes: number;
}

export interface ReportComparisonProps {
  akimatId: string;
  reportSubject: Subject;
  reportYear: number;
  taxPayerType: TaxpayerType;
}

export type ReportComparisonRating = ReportComparison & {
  akimName: string;
  akimatName: string;
  diffPercent: number;
  greenCount: number;
  redCount: number;
};

export type ReportComparisonRatingProps = Omit<
  ReportComparisonProps,
  'akimatId' | 'reportSubject'
> &
  PageableQueryParams & {
    akimatIds: string;
    subject: Subject;
  };

export interface ReportHistory {
  akimatId: string;
  file: any;
  id: number;
  isApproved: true;
  reportSubject: string;
  reportType: string;
  reportYear: number;
  uploadDt: string;
  userId: string;
  userName: string;
  version: string;
  akimatName: string;
}

export interface ReportDetailedComparsion {
  bkCode: string;
  paymentName: string;
  yearPaymentAmount: number;
  inJan: number;
  inFeb: number;
  inMar: number;
  inApr: number;
  inMay: number;
  inJun: number;
  inJul: number;
  inAug: number;
  inSep: number;
  inOct: number;
  inNov: number;
  inDec: number;
  reportYear: number;
  yearPaymentAmountPLan: number;
  yearPaymentAmountFact: number;
  difference: number;
  diffPercent: number;
}

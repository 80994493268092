import { GetterTree } from 'vuex';
import { AkimatsState, AkimatTree } from '@/store/akimats/types';
import { RootState } from '@/store/types';
import { tree } from '@/types/tree';

export const getters: GetterTree<AkimatsState, RootState> = {
  isLoaded: (state) => !!state.tree,

  // tree
  tree: (state) => state.tree,
  userTree: (state, getters, rootState, rootGetters) => {
    const code = rootGetters['user/info'].idAkim;
    if (!state.tree) return;
    return tree([state.tree])
      .filter((el) => el.code === code)
      .get()[0];
  },
  treeByCode: (state) => (code: string) => {
    if (!state.tree) return;
    return tree([state.tree])
      .filter((el) => el.code === code)
      .get()[0];
  },
  userTreeAsOptions: (state, getters) => {
    return tree([getters.userTree as AkimatTree]).map((node) => ({
      value: node.code,
      label: node.location,
    }));
  },
  branchSelection: (state) => (code: string) => {
    if (!state.tree) return;
    return tree([state.tree])
      .select((el) => el.code === code)
      .flatMap((el) => el.code);
  },
  hasManyOption: (state, getters) => {
    return !!getters.userTree?.children?.length;
  },
};
